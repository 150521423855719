import {
    UTM_INFO_COOKIE,
    FACEBOOK_CLICK_DATA,
    FACEBOOK_PIXEL_IDENTIFIER,
    FACEBOOK_PIXEL_IDENTIFIER_FIELD_NAME,
    FACEBOOK_CLICK_DATA_FIELD_NAME,
    CAMPAIGN_SOURCE,
    SITE_SOURCE,
    URL_PARAMS_TO_EXTRACT, CLICK_IDENTIFIERS,
    BRAND_SEARCH,
    GOOGLE_SEARCH
} from '../constants';

function getCurrentDate() {
    return new Date().toISOString().split('.')[0]
}

function overrideExistingDataInTheCookie(cookieObj, searchParams, param) {
    cookieObj[param] = searchParams.get(param)
}

function iterateSearchParams(array, searchParams, cookieObj) {
    array.forEach(param => {
        // don't clean if not unset
        if (searchParams.has(param)) {
            overrideExistingDataInTheCookie(cookieObj, searchParams, param);
        }
    });
}

export function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function UpdateCookieWithUrlParams() {
    let existingCookieString = getCookie(UTM_INFO_COOKIE);
    let cookieObj;
    const currentDate = getCurrentDate()
    if (existingCookieString.length === 0) {
        cookieObj = { cookieCreationTime: currentDate }
    } else {
        cookieObj = JSON.parse(existingCookieString)
    }
    const searchParams = new URLSearchParams(window.location.search);
    const utmSource = searchParams.get(SITE_SOURCE);
    const utmCampaign = searchParams.get(CAMPAIGN_SOURCE);
    const organic = !utmSource;
    const googleSearch = utmSource === GOOGLE_SEARCH && utmCampaign === BRAND_SEARCH;
    const currentUtmSource = cookieObj[SITE_SOURCE];
    if (!!currentUtmSource && currentUtmSource.length > 0 && (organic || googleSearch)) {
        return;
    }
    URL_PARAMS_TO_EXTRACT.forEach(param => {
        overrideExistingDataInTheCookie(cookieObj, searchParams, param);
    });

    iterateSearchParams(URL_PARAMS_TO_EXTRACT, searchParams, cookieObj);
    iterateSearchParams(CLICK_IDENTIFIERS, searchParams, cookieObj);

    // add fbc and fbp data
    const fbc = getCookie(FACEBOOK_CLICK_DATA);
    if (fbc) {
        cookieObj[FACEBOOK_CLICK_DATA_FIELD_NAME] = fbc
    }
    const fbp = getCookie(FACEBOOK_PIXEL_IDENTIFIER);
    if (fbp) {
        cookieObj[FACEBOOK_PIXEL_IDENTIFIER_FIELD_NAME] = fbp
    }
    // get referrer from url param since this is a redirect
    cookieObj.referrer = "";
    if (searchParams.has("r")) {
        cookieObj.referrer = searchParams.get("r");
    }
    // get hash email from url param since this is a redirect
    if (searchParams.has("he")) {
        cookieObj.he = searchParams.get("he");
    }

    cookieObj.referrer = document.referrer;
    const match = cookieObj.referrer.match(/(?:(?:https?|ftp|file):\/\/)[^\/]+/);
    cookieObj.referrer_domain = match ? match[0] : cookieObj.referrer_domain;
    cookieObj.cookieLastUpdateTime = currentDate;
    updateCookie(cookieObj);
}


export function updateCookie(props) {
    try {
        let existingCookieString = getCookie(UTM_INFO_COOKIE) || '{}';
        let cookieObj = JSON.parse(existingCookieString);
        cookieObj = { ...cookieObj, ...props }
        cookieObj.cookieLastUpdateTime = getCurrentDate();
        const expiry = new Date();
        expiry.setMonth(expiry.getFullYear() + 1);
        const expiryString = expiry.toUTCString()
        const params = isStaging() ? "; expires=" + expiryString + ";SameSite=None; Secure" :
            "; expires=" + expiryString + "; SameSite=None; Secure; Domain=ai21.com"
        document.cookie = `${UTM_INFO_COOKIE}=${JSON.stringify(cookieObj) + params}`;
    } catch (e) {
        console.log("Can't update cookie, ", e)
    }
}

export const isStaging = () => {
    return window.location.hostname.includes('ai21-labs.webflow.io');
}

export function getUrlParams() {
    return Object.fromEntries(new URLSearchParams(window.location.search));
}

