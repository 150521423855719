export const APP_NAME = 'website'
export const STUDIO_DOCS_APP_NAME = 'studio-docs'
export const ANALYTICS_APP_LOADED = 'LandingPageEntered'


// url params

export const UTM_INFO_COOKIE = 'UtmInfo';
export const FACEBOOK_CLICK_DATA = '_fbc';
export const FACEBOOK_CLICK_DATA_FIELD_NAME = 'fbc';
export const FACEBOOK_PIXEL_IDENTIFIER = '_fbp';
export const FACEBOOK_PIXEL_IDENTIFIER_FIELD_NAME = 'fbp';
export const SITE_SOURCE = 'utm_source';
export const CAMPAIGN_SOURCE = 'utm_campaign';
export const SEARCH_TERM = 'utm_term';
export const AD_SET_SOURCE = "ad_set_source";
export const AD_SET_NAME = "ad_set_name";
export const AD_SOURCE = "ad_source";
export const AD_NAME = "ad_name";
export const UTM_MEDIUM = "utm_medium";
export const IMPRESSION_DEVICE = "impression_device";
export const SRC = "src";
export const FACEBOOK_CLICK_IDENTIFIER = 'fbclid';
export const GOOGLE_CLICK_IDENTIFIER = 'gclid';
export const MICROSOFT_CLICK_IDENTIFIER = 'msclkid';
export const MOBILE = 'mobile';
export const FUI = 'fui';
export const GOOGLE_SEARCH = 'google-search';
export const BRAND_SEARCH = 'BrandSearch';
export const CONTENT = 'utm_content';


export const URL_PARAMS_TO_EXTRACT = [SITE_SOURCE, CAMPAIGN_SOURCE, SEARCH_TERM, AD_SET_SOURCE, AD_SET_NAME,
    AD_SOURCE, AD_NAME, UTM_MEDIUM, IMPRESSION_DEVICE, SRC, CONTENT];

export const CLICK_IDENTIFIERS = [FACEBOOK_CLICK_IDENTIFIER, GOOGLE_CLICK_IDENTIFIER, MOBILE, FUI, MICROSOFT_CLICK_IDENTIFIER];

